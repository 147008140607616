@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&family=Inter:wght@400;500;700&display=swap');

:root {
  --background-color: #f7f7f7;
  --text-color: #111111;
  --primary-color: #2ecc71;
  --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Space Grotesk', sans-serif;
}

.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.header h1 {
  font-family: 'Inter', sans-serif;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

@media (max-width: 768px) {
  .app {
    padding: 1rem;
  }
  
  .header h1 {
    font-size: 2rem;
  }
}

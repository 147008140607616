.meal-offset {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: var(--shadow);
  width: 100%;
  max-width: 400px;
}

.meal-offset h2 {
  margin-bottom: 2rem;
  text-align: center;
}

.meal-offset-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
}

.form-group select,
.form-group input {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-group select:focus,
.form-group input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.form-group input::-webkit-inner-spin-button,
.form-group input::-webkit-outer-spin-button {
  opacity: 1;
}

.portion-buttons {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.portion-button {
  background: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 0.75rem 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.portion-button:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.portion-button.active {
  background: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.custom-portions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.custom-portions-input {
  width: 80px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calculation-result {
  margin-top: 1rem;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #e0e0e0;
}

.calculation-result p {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  color: #2c3e50;
}

.calculation-result span {
  font-weight: 600;
  color: var(--primary-color);
}

.trees-equivalent {
  color: #27ae60 !important;
}

.trees-equivalent span {
  color: #27ae60 !important;
}

.price-amount {
  margin-top: 1rem !important;
  font-size: 1.2rem !important;
}

.price-amount span {
  font-size: 1.3rem;
}

.more-portions-button {
  margin-bottom: 0.5rem;
}

.payment-section {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-payment-container {
  width: 100%;
  max-width: none;
}

.payment-method-button.card-pay {
  width: 100%;
  max-width: none;
  padding: 1rem;
  margin: 0;
  border-radius: 8px;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.card-form {
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;
}

.payment-button {
  width: 100%;
  margin-top: 1rem;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0.5rem 0;
  color: #6b7c93;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #e0e0e0;
}

.separator::before {
  margin-right: .5em;
}

.separator::after {
  margin-left: .5em;
}

.card-payment-container {
  width: 100%;
}

.payment-method-button {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  transition: all 0.2s;
}

.apple-pay {
  background: black;
  color: white;
}

.google-pay {
  background: white;
  border-color: #3c4043;
  color: #3c4043;
}

.card-pay {
  background: white;
  color: #424770;
}

.card-form {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;
}

.card-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.cancel-button {
  padding: 0.75rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: white;
  color: #6b7c93;
}

.card-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.card-error {
  color: #e74c3c;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}